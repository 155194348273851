import React from "react"
import PropTypes from "prop-types"
import styles from "./index.module.css"

const VideoEmbed = ({ }) => (
  <div className={styles.videoResponsive}>
    <div
      contentEditable="true"
      dangerouslySetInnerHTML={{
        __html: `
<script type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script>

<img
  style="width: 100%; margin: auto; display: block;"
  class="vidyard-player-embed"
  src="https://play.vidyard.com/o4GDoGPfcRM9VQ5HAiMJAD.jpg"
  data-uuid="o4GDoGPfcRM9VQ5HAiMJAD"
  data-v="4"
  data-type="inline"
/>`,
      }}
    ></div>
  </div>
)

export default VideoEmbed
