import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import VideoEmbed from "../../videoPlayer"
import { getUrl } from "../../header"

export default ({ currency }) => {
  return (
    <>
      <div
        style={{
          minHeight:
            typeof window !== "undefined" ? window.innerHeight : "100vh",
        }}
        className={`container flex flex-col md:flex-row justify-between items-center mt-auto mx-auto`}
      >
        <div className={"flex-1 text-center md:text-left md:mr-4"}>
          <h1 className={"md:text-5xl text-3xl mt-40 md:mt-0 font-extrabold mx-4"}>
            Untapped talent meets career-defining roles in FinTech
            <span className={"text-red"}>.</span>
          </h1>
          <p className={"md:text-2xl text-lg mx-4 mt-1"}>
            Refer someone to a new job & earn referral rewards when they
            interview
          </p>

          <div className={"flex flex-col text-center gap-4 md:flex-row mx-4 mt-5 mb-5 "}>

            <a
              href={"https://app.referment.com"}
              rel="noopener noreferrer"
              target="_blank"
              className={"btn md:text-lg py-8"}
            >
              View Our Open Jobs
            </a>

            <Link
              className={"btn-outline border-white md:text-lg py-1 md:mr-8"}
              to={getUrl("clients")}
            >
              Hire with Referment
            </Link>
          </div>
        </div>
        <div className={"flex-1 md:my-4 my-24 w-full"}>
          <VideoEmbed />
        </div>
      </div>
    </>
  )
}
