import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal"

export default () => {
  const data = useStaticQuery(graphql`
    query ApplyRoles {
      file(relativePath: { eq: "Referment-Applying-Yourself-Laptop.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container flex justify-between items-center lg:mt-24 mx-auto ${styles.main}`}
    >
      <div className={"w-full"}>
        <div className={`${styles.content}`}>
          <Fade cascade>
            <div className={"flex-1 my-auto"}>
              <Img
                loading={"eager"}
                className={"lg:mt-0 lg:mx-0 mx-2  mt-16 mb-4"}
                fluid={data.file.childImageSharp.fluid}
              />
            </div>
            <div className={" flex-col flex-1 lg:mr-0 mx-8 justify-between lg:my-auto"}>
              <h2 className={"heading mb-4"}>
                Applying yourself<span className={"red"}>.</span>
              </h2>
              <div className={"mb-4"}>
                {/* <h3 className={"sub-heading"}>Browse</h3> */}
                <p className={"body-text"}>
                  You can apply to any roles on the Referment platform and our
                  relationship managers are on hand to help you. The process of
                  applying to a role is exactly the same but you won’t be
                  eligible for any referral rewards.
                </p>
              </div>
              <div className={"mb-4"}>
                {/* <h3 className={"sub-heading"}>Succeed</h3> */}
                <p className={"body-text"}>
                  To make an application, simply click apply and tell us why
                  you’d be a good fit for the role. Then, be sure to include a
                  copy of your CV, your LinkedIn profile and some contact
                  details so our team can get in touch to discuss your
                  application and help you find your next career-defining role
                  in FinTech!
                </p>
              </div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://app.referment.com/"
                style={{ width: "fit-content" }}
                className={
                  "block btn-lg lg:mb-0  ml-auto mr-auto lg:ml-0 mt-8"
                }
              >
                Apply Now
              </a>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
