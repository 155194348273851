import React from "react"
import Fade from "react-reveal"
import Slide from 'react-reveal/Slide';

export default () => {
  return (
    <div
      className={`container flex justify-between items-center mt-32 lg:mt-auto mb-auto mx-auto min-h-screen`}
    >
      <div className={"w-full"}>
        <Fade>
          <h2 className="mb-2 heading-center">
            {" "}
            Let's talk data<span className={"red"}>.</span>
          </h2>
        </Fade>
        <Fade>
          <h4 className={"text-lg lg:text-xl mb-4 text-center"}>
            Protecting and securing your data is Referment’s top priority
          </h4>
        </Fade>
        <div
          className={
            "container mx-auto flex justify-center items-center lg:items-stretch lg:flex-row flex-col"
          }
        >
          <Slide left>
            <div
              className={
                "bg-primaryLight shadow-lg rounded p-6 max-w-md lg:px-12 px-8 pb-16 flex-1 m-6 flex-col flex text-center"
              }
            >
              <div className={"text-secondary mb-4 font-bold text-2xl"}>
                {" "}
                Your data
              </div>
              <div className={"text-light"}>
                We don’t share your data with any third party vendors. <br />{" "}
                <br /> We’re happy to make your referral anonymous if you don’t
                want the candidate knowing who made the referral. <br /> <br />{" "}
                You can opt-in or opt-out of marketing. Marketing includes us
                sending you the latest referral opportunities.
                <br />
              </div>
            </div>
          </Slide>
          <Slide right>
            <div
              className={
                "bg-primaryLight shadow-lg p-6 max-w-md lg:px-12 px-8 rounded flex-1 m-6 flex-col flex text-center"
              }
            >
              <div className={"text-secondary mb-4 font-bold text-2xl"}>
                Your referral's data
              </div>
              <div className={"text-light rounded"}>
                We don’t share your referrals data with any third party vendors.
                <br /> <br /> Once you’ve made a referral, we’ll use the
                information you’ve provided to get in touch with the person to
                discuss the role. <br /> <br /> If your referral agrees and
                provide us with their CV, we will share that with the hiring
                company alone. We don’t share candidate CV’s with any third
                parties.
                <br />
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  )
}
