import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "./index.module.css"

export default () => {
  const data = useStaticQuery(graphql`
    query BlogData {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              title
              coverImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`container mx-auto lg:mt-24 mt-24 block ${styles.main}`}>
      <h2 className={"heading-center mt-16 mb-4"}>
        The Referment blog<span className={"red"}>.</span>
      </h2>
      <div className="grid lg:grid-cols-2">
        {data.allMarkdownRemark.edges.map(data => (
          <>
            <Blog data={data} />
          </>
        ))}
      </div>
      <div>
        <Link
          to="blog"
          style={{ width: "fit-content" }}
          className={"block btn-lg ml-auto mr-auto mt-3"}
        >
          Read More
        </Link>
      </div>
    </div>
  )
}

export const Blog = ({ data }) => {
  return (
    <Link
      to={`blog/post${data.node.fields.slug}`}
      className={"bg-light flex lg:flex-row flex-col rounded row-span-1 m-5"}
    >
      <div>
        <Img
          className={styles.blogImg}
          loading={"eager"}
          // style={{
          //   height: "150px",
          //   width: "150px",
          //   borderRadius: "0.25rem 0 0rem 0.25rem",
          // }}
          fluid={data.node.frontmatter.coverImage.childImageSharp.fluid}
        />
      </div>
      <div className={"m-5"}>
        <div className={"text-primary font-semibold"}>
          {data.node.frontmatter.title}
        </div>
        <div className={"text-primary text-sm mt-2  "}>{data.node.excerpt}</div>
      </div>
    </Link>
  )
}
