import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal"

export default () => {
  const data = useStaticQuery(graphql`
    query TailorReferrals {
      file(relativePath: { eq: "Tailor your Referment Job Match Phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container flex justify-between items-center mt-24 mx-auto ${styles.main}`}
    >
      <div className={"w-full"}>
        <div className={`${styles.content}`}>
          <Fade cascade>
            <div className={"lg:flex-col flex-1 mx-8 my-auto justify-between"}>
              <h2 style={{ whiteSpace: "nowrap" }} className={"heading mb-4"}>
                Tailor your Referment<span className={"red"}>.</span>
              </h2>
              <div className={"mb-4"}>
                <p className={"body-text"}>
                  You can now choose exactly what types of roles you want to
                  hear about by filling out your job notification preferences in
                  your profile on the Referment Platform.
                </p>
              </div>
              <div className={"mb-4"}>
                <p className={"body-text"}>
                  Select what industry, job functions and locations you’re
                  interested in and we’ll send you an email when a new job hits
                  the platform that matches your preferences.
                </p>
              </div>
              <div className={"mb-4"}>
                <p className={"body-text"}>
                  From there you can either apply to new roles yourself or refer
                  the people you know who you think would be a good fit. Plus,
                  you can toggle notifications on and off in the profile section
                  too.
                </p>
              </div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://app.referment.com/profile"
                style={{ width: "fit-content" }}
                className={
                  "block btn-lg mb-24 lg:mb-0  ml-auto mr-auto lg:ml-0 mt-8"
                }
              >
                Update Your Preferences
              </a>
            </div>
            <div className={"flex-1 my-auto"}>
              <Img
                loading={"eager"}
                className={"ml-3 hidden lg:block lg:mt-0  mt-24"}
                fluid={data.file.childImageSharp.fluid}
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
