import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import { getCurrency } from "../../utils"

export default ({ currency }) => {
  const data = useStaticQuery(graphql`
    query Rewards {
      uk: file(
        relativePath: { eq: "Referment-What-Is-A-Referral-Laptop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      au: file(
        relativePath: { eq: "Referment-What-Is-A-Referral-AU.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container flex justify-between items-center lg:mt-24 mx-auto ${styles.main}`}
    >
      <div className={"w-full"}>
        <div className={`${styles.content}`}>
          <Fade cascade>
            <div
              className={" flex-col flex-1 mx-8 lg:mr-0 justify-between mb-10"}
            >
              <h2 className={"heading mb-4"}>
                What is a referral<span className={"red"}>?</span>
              </h2>
              <div className={"mb-4"}>
                {/* <h3 className={"sub-heading"}>A helping hand</h3> */}
                <p className={"body-text"}>
                  You’ve spent time and effort curating your professional
                  network and Referment was built to help you get the most out
                  of that.
                </p>
              </div>
              <div className={"mb-4"}>
                {/* <h3 className={"sub-heading"}>Referral rewards</h3> */}
                <p className={"body-text"}>
                  A referral is a helping hand, a way of paying it forward by
                  connecting people you know with amazing new jobs across the
                  FinTech industry. Making a referral takes as little as 1
                  minute but the more information you can share, the better.
                </p>
              </div>
              <div className={"mb-4"}>
                {/* <h3 className={"sub-heading"}>Referral rewards</h3> */}
                <p className={"body-text"}>
                  Our more successful referrers have already spoken to their
                  referral in order to upload a copy of their CV and share their
                  LinkedIn profile.
                </p>
              </div>
              <div className={"mb-4"}>
                {/* <h3 className={"sub-heading"}>Referral rewards</h3> */}
                <p className={"body-text"}>
                  Then, once your referral has completed a first-round
                  interview, you get rewarded!
                </p>
              </div>
              <a
                href="https://app.referment.com/"
                rel="noopener noreferrer"
                target="_blank"
                style={{ width: "fit-content" }}
                className={"block  btn-lg  ml-auto mr-auto lg:ml-0 mt-8"}
              >
                Make a Referral
              </a>
            </div>
            <div className={"flex-1 my-auto"}>
              <Img
                loading={"eager"}
                className={"mx-4 mb-4 mt-16 lg:mx-0"}
                fluid={data[currency].childImageSharp.fluid}
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
