import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
// import { getCurrency } from "../../utils"
// import YoutubeEmbed from "../../videoPlayer"

export default ({ currency }) => {
  const data = useStaticQuery(graphql`
    query Hero {
      uk: file(relativePath: { eq: "Referment-Main-Hero-Image-2021.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      au: file(relativePath: { eq: "Referment-Main-Hero-Image-2021-AU.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container flex justify-between items-center mt-auto mx-auto ${styles.main}`}
    >
      <div className={"w-full"}>
        <div className={`${styles.content}`}>
          <div
            className={
              " flex-col text-center lg:text-left flex-1 mx-8 justify-between my-10"
            }
          >
            <Fade>
              <h2 className={"heading mb-4"}>
                How it works<span className={"red"}>.</span>
              </h2>
              <div className={"mb-4"}>
                <h3 className={"sub-heading"}>Browse</h3>
                <p className={"body-text"}>
                  Sign-up to Referment in under 30 seconds and browse our
                  current FinTech roles. You can filter by company type, job
                  function and location too.
                </p>
              </div>
              <div className={"mb-4"}>
                <h3 className={"sub-heading"}>Refer</h3>
                <p className={"body-text"}>
                  Match the superstars in your network with their next
                  career-defining role by referring them on the platform,
                  letting us know why they’d be good for the role.
                </p>
              </div>
              <div className={"mb-4"}>
                <h3 className={"sub-heading"}>Earn</h3>
                <p className={"body-text"}>
                  We’ll then speak to them about the role and if they’re
                  interested and progress to a first-round interview you’ll get
                  a referral reward.
                  {/* The more you refer the more you earn. You'll earn{" "}
                  {getCurrency(currency, 250)} for your first referral and move
                  up {getCurrency(currency, 50)} for each subsequent referral
                  until you hit Referminator status, where you'll earn{" "}
                  {getCurrency(currency, 500)} per interview! */}
                </p>
              </div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://app.referment.com/"
                style={{ width: "fit-content" }}
                className={"block btn-lg  ml-auto mr-auto lg:ml-0 mt-8"}
              >
                View Our Open Jobs
              </a>
            </Fade>
          </div>

          <Fade>
            <div className={"flex-1 my-auto mx-4"}>
              <Img
                loading={"eager"}
                fluid={data[currency].childImageSharp.fluid}
              />
              {/* <YoutubeEmbed embedId={"psZlYPtWMkM"} /> */}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}
