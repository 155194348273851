import React from "react"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import Cookies from "js-cookie"

import Main from "../components/homepageComponents/titlePageV2"
import HowItWorks from "../components/homepageComponents/howItWorks"
import WhyRef from "../components/homepageComponents/whyReferment"
import Apply from "../components/homepageComponents/applyToRoles"
import RefMo from "../components/homepageComponents/referralRewards"
import ClientReview from "../components/homepageComponents/clientsReview"
import Blog from "../components/homepageComponents/blog"
import PrivacyData from "../components/homepageComponents/dataPrivacy"
import TailorReferrals from "../components/homepageComponents/tailerYourReferment"

const IndexPage = props => {
  const [showNav, setShowNav] = React.useState(false)

  const [currency, setCurrency] = React.useState("uk")

  React.useEffect(() => {
    setCurrency(props.pathContext.currency || "uk")
    if (props.pathContext.currency) {
      Cookies.set("country", props.pathContext.currency, { expires: 7 })
      Cookies.set("isBase", 0, { expires: 7 })
    } else {
      Cookies.set("country", "uk", { expires: 7 })
      Cookies.set("isBase", 1, { expires: 7 })
    }
  }, [])

  const onScroll = e => {
    if (e.target.scrollTop > 100) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
  }
  return (
    <>
      <SEO title="Home" />
      <Layout
        showNav={showNav}
        isBase={!props.pathContext.currency}
        page={"client"}
        currency={currency}
      >
        <div onScroll={e => onScroll(e)}>
          <section class="">
            <Main currency={currency} />
          </section>

          <section class="">
            <HowItWorks currency={currency} />
          </section>

          <section class="">
            <RefMo currency={currency} />
          </section>

          <section class="">
            <WhyRef currency={currency} />
          </section>

          <section class="">
            <Apply />
          </section>

          <section>
            <TailorReferrals />
          </section>

          <section class="">
            <ClientReview />
          </section>

          <section class="">
            <Blog />
          </section>

          <section class="">
            <PrivacyData />
          </section>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
